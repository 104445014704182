import React from 'react'

const Footer = () => {
  return (
    <footer className='bg-dark text-light py-3 '  >

      <p className="text-center foot" >
      Copyright &copy; NewsMonk 2023
      </p>
      <p className="text-center foot">Designed and Developed by Naman Arora</p>
    </footer>
  )
}

export default Footer
